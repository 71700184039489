import React, { useCallback, useEffect, useState } from 'react'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import MainDataCard from '../../components/mainDataCard/MainDataCard'
import { IProductData } from '../brand/brand.interface'
import {
  getFirestore,
  getDoc,
  doc,
  collection,
  onSnapshot,
  getDocs
} from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd'
import BackButton from '../../components/backButton/BackButton'
import { Radio } from 'antd'
import ProductDashboard from './productDetailsTabs/dashboard/ProductDashboard'
import ConsumerProfile from './productDetailsTabs/consumeProfile/ConsumerProfile'
import { ReactComponent as DashboardBlueIcon } from '../../icons/dashboard_blue_icon.svg'
import { ReactComponent as DashboardWhiteIcon } from '../../icons/dashboard_white_icon.svg'
import { ReactComponent as ProfileWhiteIcon } from '../../icons/profile_white_icon.svg'
import { ReactComponent as ProfileBlueIcon } from '../../icons/profile_blue_icon.svg'
import { ReactComponent as AnalysisWhiteIcon } from '../../icons/analysis_white_icon.svg'
import { ReactComponent as AnalysisBlueIcon } from '../../icons/analysis_blue_icon.svg'
import { ReactComponent as ExclamationBlueIcon } from '../../icons/exclamation_thin.svg'
import AnalysisTab from './productDetailsTabs/analysis/Analysis'
import {
  IAnalysisData,
  IConsumerProfileData,
  IProductAlertsData
} from './product.interface'
import './ProductDetails.scss'
import AlertsTab from './productDetailsTabs/alerts/Alerts'

interface IDataBlockWithSortOrder extends IAnalysisData {
  sort_order: number
}

type dataTabs = 'dashboard' | 'profile' | 'analysis' | 'alerts'

const ProductDetails = () => {
  const { userData } = useUser()
  const { productId } = useParams()
  const [activeTab, setActiveTab] = useState<dataTabs>('dashboard')
  const [productData, setProductData] = useState<IProductData>()
  const [isLoading, setIsLoading] = useState(false)
  const [analysisData, setAnalysisData] = useState<IAnalysisData>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [consumerProfileData, setConsumerProfileData] =
    useState<IConsumerProfileData>()
  const [productAlertsData, setProductAlertsData] =
    useState<IProductAlertsData>()

  const handleTabChange = (key: dataTabs) => {
    setActiveTab(key)
    if (key === 'profile' && !consumerProfileData) {
      getProfileData()
    } else if (key === 'alerts' && !consumerProfileData) {
      getAlertsData()
    }
  }

  useEffect(() => {
    userData?.brand_id && fetchProduct(userData?.brand_id, productId)
  }, [productId, userData])

  const fetchProduct: Function = useCallback(
    async (brandId: string, product: string): Promise<void> => {
      setIsLoading(true)
      try {
        const db = getFirestore()
        const productDocRef = doc(db, 'brands', brandId, 'products', product)
        const productDoc = await getDoc(productDocRef)

        if (productDoc.exists()) {
          //@ts-ignore
          setProductData(productDoc.data())
        } else {
          console.error('Product not found')
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    },
    [productId]
  )

  const sortBySortOrder = (data: IAnalysisData) => {
    const dataArray: IDataBlockWithSortOrder[] = Object.values(
      data
    ) as IDataBlockWithSortOrder[]
    return dataArray.sort((a, b) => a.sort_order - b.sort_order)
  }

  const getProfileData: Function = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    const brandId: string = userData?.brand_id || ''
    try {
      if (productId) {
        const db = getFirestore()
        const profileDocRef = collection(
          db,
          'brands',
          brandId,
          'products',
          productId,
          'consumerProfile'
        )

        // Subscribe to snapshot changes
        const profileDoc = await getDocs(profileDocRef)
        if (!profileDoc.empty) {
          //@ts-ignore
          setConsumerProfileData(profileDoc.docs[0].data())
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const getAlertsData: Function = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    const brandId: string = userData?.brand_id || ''
    try {
      if (productId) {
        const db = getFirestore()
        const alertsDocRef = collection(
          db,
          'brands',
          brandId,
          'products',
          productId,
          'alerts'
        )

        const alertsDoc = await getDocs(alertsDocRef)
        if (!alertsDoc.empty) {
          //@ts-ignore
          setProductAlertsData(alertsDoc.docs[0].data())
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const fetchAnalysis = () => {
    setIsLoading(true)
    const brandId: string = userData?.brand_id || ''
    try {
      if (productId) {
        const db = getFirestore()
        const q = collection(
          db,
          'brands',
          brandId,
          'products',
          productId,
          'analysis'
        )

        // Subscribe to snapshot changes
        const unsubscribe = onSnapshot(q, (snapshot) => {
          let newAnalysisData: IAnalysisData | undefined = undefined

          snapshot.docChanges().forEach((change) => {
            const data = change.doc.data()
            if (change.type === 'added' || change.type === 'modified') {
              newAnalysisData = data as any
            }
            if (change.type === 'removed') {
              // Optionally handle removed documents if needed
            }
          })
          if (newAnalysisData) {
            // @ts-ignore
            const sortedData: IAnalysisData = sortBySortOrder(newAnalysisData) // Sort the data
            setAnalysisData(sortedData)
          } else {
            setAnalysisData(undefined)
            setIsLoading(false)
            setErrorMessage('No Analysis & Recommendations')
          }
        })

        // Store unsubscribe function for cleanup
        return () => unsubscribe()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const getTabContent = (tab: dataTabs, data: IProductData) => {
    switch (tab) {
      case 'dashboard':
        return <ProductDashboard productData={data} />
      case 'profile':
        return (
          <ConsumerProfile
            isLoadingData={isLoading}
            consumerProfileData={consumerProfileData}
          />
        )
      case 'analysis':
        return (
          <AnalysisTab
            brandId={userData?.brand_id || ''}
            isLoadingData={isLoading}
            analysisData={analysisData}
            errorMessage={errorMessage}
            handleGetAnalysisData={() => fetchAnalysis()}
          />
        )
      case 'alerts':
        return (
          <AlertsTab
            productAlertsData={productAlertsData}
            isLoading={isLoading}
          />
        )
      default:
        return ''
    }
  }

  const tabPanel = () => {
    return (
      <Radio.Group
        defaultValue="dashboard"
        className="flex justify-center max-sm:flex-col [&_.ant-radio-button-wrapper]:h-12 [&_.ant-radio-button-wrapper]:flex [&_.ant-radio-button-wrapper]:items-center [&_.ant-radio-button-wrapper]:justify-center [&_.ant-radio-button-wrapper]:px-5  [&_.ant-radio-button-wrapper]:bg-cons-blue/5 [&_.ant-radio-button-wrapper-checked]:bg-cons-blue [&_.ant-radio-button-wrapper-checked]:text-white ![&_.ant-radio-button-wrapper-checked]:bg-green-700"
        optionType="button"
        buttonStyle="solid"
        onChange={(event) => handleTabChange(event.target.value)}
      >
        <Radio.Button value="dashboard">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'dashboard' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-dashboard mr-3 h-6">
              {activeTab === 'dashboard' ? (
                <DashboardWhiteIcon />
              ) : (
                <DashboardBlueIcon />
              )}
            </i>
            Dashboard
          </div>
        </Radio.Button>
        <Radio.Button value="alerts">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'alerts' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-dashboard mr-3 h-6">
              <ExclamationBlueIcon
                width="22px"
                height="22px"
                fill={`${activeTab === 'alerts' ? 'white' : '#2F5497'}`}
              />
            </i>
            Alerts
          </div>
        </Radio.Button>
        <Radio.Button value="profile">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'profile' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-user mr-3 h-6">
              {activeTab === 'profile' ? (
                <ProfileWhiteIcon />
              ) : (
                <ProfileBlueIcon />
              )}
            </i>
            Consumer Profile
          </div>
        </Radio.Button>
        <Radio.Button value="analysis">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'analysis' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-analysis mr-3 h-6">
              {activeTab === 'analysis' ? (
                <AnalysisWhiteIcon />
              ) : (
                <AnalysisBlueIcon />
              )}
            </i>
            Analysis & Recommendations
          </div>
        </Radio.Button>
      </Radio.Group>
    )
  }

  return (
    <div className="max-w-6xl w-4/5 mx-auto mb-12 relative">
      {!isLoading && <BackButton />}
      {productData ? (
        <>
          <MainDataCard cardData={productData} id={productId} />
          <div className="mt-6">{tabPanel()}</div>
          {productData && getTabContent(activeTab, productData)}
        </>
      ) : isLoading ? (
        <Spin className="w-full flex justify-center pt-[50px]" />
      ) : (
        <div className="font-semibold text-center text-[larger] mt-[60px]">
          Product Not Fount
        </div>
      )}
    </div>
  )
}

export default ProductDetails
