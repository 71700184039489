import React, { useEffect, useState } from 'react'
import { Modal, Spin } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ReactComponent as LikeIcon } from '../../../../icons/like_icon.svg'
import { ReactComponent as DislikeIcon } from '../../../../icons/dislike_icon.svg'
import { ReactComponent as PricingIcon } from '../../../../icons/pricing_icon.svg'
import { ReactComponent as ValuesIcon } from '../../../../icons/values_icon.svg'
import { IConsumerProfileData } from '../../product.interface'
import parse from 'html-react-parser'
import '../analysis/Analysis.scss'

interface IDataBlockWithSortOrder extends IConsumerProfileData {
  sort_order: number
}

const ConsumerProfile = ({
  consumerProfileData,
  isLoadingData
}: {
  consumerProfileData?: IConsumerProfileData
  isLoadingData?: boolean
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [modalType, setModalType] = useState('')
  const [sortedConsumerProfileData, setSortedConsumerProfileData] = useState<
    IConsumerProfileData[]
  >([])

  const handleReadMore = (content: string, type: string) => {
    setModalContent(content)
    setModalType(type)
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    if (consumerProfileData) {
      const dataArray: IDataBlockWithSortOrder[] = Object.values(
        consumerProfileData
      ) as IDataBlockWithSortOrder[]
      const sorted = dataArray?.sort(
        (a, b) => a && b && a.sort_order - b.sort_order
      )
      setSortedConsumerProfileData(sorted)
    }
  }, [consumerProfileData])

  const getIcon = (type: string) => {
    switch (type) {
      case 'likes':
        return <LikeIcon />
      case 'dislikes':
        return <DislikeIcon />
      case 'pricing':
        return <PricingIcon />
      case 'values':
        return <ValuesIcon />
      default:
        return ''
    }
  }

  const isArrayEmptyOrHasOnlyNulls = (arr: IConsumerProfileData[]) => {
    return (
      arr.length === 0 ||
      arr.every((item) => item === null || Object.keys(item || {}).length === 0)
    )
  }

  if (isArrayEmptyOrHasOnlyNulls(sortedConsumerProfileData)) {
    return (
      <div className="mt-8 text-center text-gray-500">
        Unfortunately, we do not have customer feedback from reviews on this
        topic right now.
      </div>
    )
  }

  return (
    <div className="mt-8">
      {isLoadingData && (
        <div className="flex w-full justify-center text-center items-center h-40">
          <Spin size="large" />
        </div>
      )}

      {/* Check if the array is empty or contains only nulls */}
      {(!sortedConsumerProfileData.length ||
        sortedConsumerProfileData.every(
          (item) => item === null || Object.keys(item || {}).length === 0
        )) && (
        <div className="text-center text-gray-500">
          Unfortunately, we do not have customer feedback from reviews on this
          topic right now.
        </div>
      )}

      {/* Render blocks only if there is valid data */}
      {sortedConsumerProfileData.length > 0 &&
        sortedConsumerProfileData.some(
          (item) => item && Object.keys(item).length > 0
        ) && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {sortedConsumerProfileData.slice(0, 2).map((block, index) =>
                block ? (
                  <div
                    key={index}
                    className="relative p-6 border rounded-lg shadow-lg bg-white"
                  >
                    <div className="flex justify-between items-start">
                      <div className="text-blue-600 text-2xl mb-2">
                        {getIcon(block.type || '')}
                      </div>
                      <InfoCircleOutlined className="text-gray-400 text-xl" />
                    </div>
                    <h3 className="text-xl font-bold mb-2 text-cons-blue">
                      {block.title}
                    </h3>
                    <p className="text-gray-600 line-clamp-2">
                      {parse(block.full_text)}
                    </p>
                    <button
                      onClick={() =>
                        handleReadMore(block.full_text, block.title)
                      }
                      className="text-cons-blue font-medium hover:underline mt-2"
                    >
                      Read More
                    </button>
                  </div>
                ) : null
              )}
            </div>

            {/* Remaining blocks displayed one per row */}
            <div className="grid grid-cols-1 gap-4 mt-4">
              {sortedConsumerProfileData.slice(2).map((block, index) =>
                block ? (
                  <div
                    key={index}
                    className="relative p-4 border rounded-lg shadow-md bg-white"
                  >
                    <div className="flex justify-between items-start">
                      <div className="text-blue-600 text-2xl mb-2">
                        {getIcon(block.type || '')}
                      </div>
                      <InfoCircleOutlined className="text-gray-400 text-xl" />
                    </div>
                    <h3 className="text-xl font-bold mb-2 text-cons-blue">
                      {block.title}
                    </h3>
                    <p className="text-gray-600 line-clamp-2">
                      {parse(block.full_text)}
                    </p>
                    <button
                      onClick={() =>
                        handleReadMore(block.full_text, block.title)
                      }
                      className="text-cons-blue font-medium hover:underline mt-2"
                    >
                      Read More
                    </button>
                  </div>
                ) : null
              )}
            </div>
          </>
        )}

      <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
        <h2 className="text-cons-blue">{modalType}</h2>
        <p className="pt-6 text-box">{parse(modalContent)}</p>
      </Modal>
    </div>
  )
}

export default ConsumerProfile
