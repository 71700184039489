import React, { createContext, useContext, useState, useEffect } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  getFirestore,
  query,
  collection,
  where,
  getDocs
} from 'firebase/firestore'

export interface IUserProvider {
  userData: IUserData | null
  logout: () => void
}
export interface IUserData {
  user_id?: string
  brand_id?: string
  name?: string
}

const defaultUserContext: IUserProvider = {
  userData: null,
  logout: () => {}
}

// Create the context
const UserContext = createContext<IUserProvider>(defaultUserContext)

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext)

// Provider component
export const UserProvider = ({ children }: any) => {
  const auth = getAuth()
  // const [brandId, setBrandId] = useState('')
  const [userData, setUserData] = useState<IUserData | null>(null)

  useEffect(() => {
    const firestore = getFirestore()

    onAuthStateChanged(auth, async () => {
      const user = auth.currentUser
      if (user) {
        try {
          // Query the users collection for the document with uuid === user.uid
          const q = query(
            collection(firestore, 'users'),
            where('user_id', '==', user.uid)
          )
          const querySnapshot = await getDocs(q)
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data()
            // @ts-ignore
            setUserData(userData) // Assuming 'name' is a field in the user document
          } else {
            console.log('No matching documents.')
          }
        } catch (error) {
          console.error('Error retrieving user information:', error)
        }
      }
    })
  }, [auth])

  const logout = () => {
    setUserData(null)
  }

  return (
    <UserContext.Provider value={{ userData, logout }}>
      {children}
    </UserContext.Provider>
  )
}
